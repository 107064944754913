<div class="row">
    <div class="col-12 d-flex justify-content-end">
        <i18n-toggle></i18n-toggle>
    </div>
</div>

<div class="row" *ngIf="form">
    <div class="col-12 col-lg-10">
        <m3-calc-objects [form]="form" [m3Object]="m3Object"></m3-calc-objects>
    </div>
    <div class="col-12 offset-md-4 offset-lg-0 col-md-4 col-lg-2">
        <m3-calc-total [form]="form" [m3Object]="m3Object" [listItems]="true"></m3-calc-total>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12 d-flex justify-content-end">
        <button *ngIf="form" class="btn btn-primary" (click)="submit()" [disabled]="form.invalid || isSubmitting">
            <fa-icon *ngIf="!isSubmitting" [icon]="['fal', 'check']" [fixedWidth]="true"></fa-icon>
            <fa-icon *ngIf="isSubmitting" [icon]="['fal', 'spinner-third']" [fixedWidth]="true" [spin]="true"></fa-icon>

            <span i18n="@@m3Calculator_form_continueEstimate" app-i18n="m3Calculator_form_continueEstimate">Ga door met offerte</span>
        </button>
    </div>
</div>
