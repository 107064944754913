import { Component } from '@angular/core'

/** Failsafe error component that displays contact information. */
@Component({
    selector: 'app-error',
    standalone: true,
    imports: [],
    template: ` <div class="col-12">
        <div class="container">
            <div class="d-flex justify-content-center align-items-center vh-100">
                <div class="d-flex flex-column justify-content-center">
                    <!-- Title -->
                    <h2 class="text-center mb-5">Oeps! Er is iets misgegaan. Probeer het later opnieuw.</h2>
                </div>
            </div>
        </div>
    </div>`,
})
export class ErrorComponent {}
