import { NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { M3CalculatorService } from 'app/_services/m3-calculator.service'
import { OrderworkService } from 'app/_services/orderwork.service'
import { M3ObjectsTabsetComponent } from 'app/m3-calculator/objects-tabset/objects-tabset.component'
import { M3TotalComponent } from 'app/m3-calculator/total/total.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ToastrService } from 'ngx-toastr'
import { I18nToggleComponent } from '../shared/i18n-toggle/i18n-toggle.component'
import { I18nDirective } from 'app/shared/directives/app-i18n.directive'
import { I18nService } from 'app/_services/i18n.service'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'app-m3-calculator-pre',
    templateUrl: './m3-calculator-pre.component.html',
    standalone: true,
    imports: [NgIf, M3ObjectsTabsetComponent, M3TotalComponent, FontAwesomeModule, BsDropdownModule, I18nToggleComponent, I18nDirective],
})
export class M3CalculatorPreComponent implements OnInit {
    // For m3 calculator
    form
    m3Object

    // While loading
    isSubmitting = false

    constructor(
        private fb: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private m3CalculatorService: M3CalculatorService,
        private orderworkService: OrderworkService,
        private toastrService: ToastrService,
        private i18nService: I18nService
    ) {}

    ngOnInit() {
        this.initLanguage().then(() => this.initForms())
    }

    async initLanguage() {
        const params = this.activatedRoute.snapshot.params
        const queryParams = this.activatedRoute.snapshot.queryParams

        const res = await firstValueFrom(this.m3CalculatorService.read(params, queryParams))
        const data = res.data

        this.m3Object = data.m3
    }

    async initForms() {
        this.form = this.fb.group(this.m3CalculatorService.createM3CategoryGroupHolder(this.m3Object))
    }

    async submit() {
        this.isSubmitting = true

        try {
            // Make orderwork
            const params = this.activatedRoute.snapshot.params
            const queryParams = this.activatedRoute.snapshot.queryParams
            const res0 = await firstValueFrom(this.orderworkService.read('removal', params, queryParams, true))

            const orderwork = await res0.data.orderwork
            const orderworkId = orderwork._id

            // Update orderwork with m3 calculator
            orderwork.estimate.m3Calculator = this.form.value
            await firstValueFrom(this.orderworkService.save(orderwork))

            // Finished, go to next step
            this.doRedirect(orderworkId)

            this.isSubmitting = false
        } catch (ex) {
            this.toastrService.error(ex.message)
            this.isSubmitting = false
        }
    }

    /** Redirect user to removal-step component with current orderworkId */
    doRedirect(orderworkId) {
        // Redirects
        const affiliate = this.activatedRoute.snapshot.params.affiliate
        const newUrl = `/form/removal/${this.i18nService.languageId}/${affiliate}${`&orderworkId=${orderworkId}`}`
        window.top.location.href = newUrl
    }
}
